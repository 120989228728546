.blog {
    margin-bottom: 60px;
    &__inner {
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
    }
    &__info {
        padding: 48px 30px;
    }
    &__img {
        background-position: center;
        background-size: cover;
    }
}